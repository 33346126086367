import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "installation"
    }}>{`Installation`}</h1>
    <p>{`Installing the SwiftQ system is quite easy and only requires that you have the following:`}</p>
    <ul>
      <li parentName="ul">{`Running Linux/Mac`}</li>
      <li parentName="ul">{`Docker`}</li>
      <li parentName="ul">{`Git`}</li>
      <li parentName="ul">{`Golang`}</li>
      <li parentName="ul">{`Node (v15.x)`}</li>
    </ul>
    <h1 {...{
      "id": "installation-using-virtulbox"
    }}>{`Installation using VirtulBox`}</h1>
    <p>{`Download Ubuntu from their website
`}<a parentName="p" {...{
        "href": "https://ubuntu.com/download/desktop"
      }}>{`https://ubuntu.com/download/desktop`}</a></p>
    <p>{`Then Download VirtualBox
`}<a parentName="p" {...{
        "href": "https://www.virtualbox.org/wiki/Downloads"
      }}>{`https://www.virtualbox.org/wiki/Downloads`}</a></p>
    <p>{`To install Ubuntu on VirtuaBox you can use this website for help
`}<a parentName="p" {...{
        "href": "https://brb.nci.nih.gov/seqtools/installUbuntu.html"
      }}>{`https://brb.nci.nih.gov/seqtools/installUbuntu.html`}</a></p>
    <p>{`To download Docker you can use this website
`}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/install/ubuntu/"
      }}>{`https://docs.docker.com/engine/install/ubuntu/`}</a>{`
And use this one to set it up
`}<a parentName="p" {...{
        "href": "https://linuxhint.com/setup_docker_machine_virtualbox/"
      }}>{`https://linuxhint.com/setup_docker_machine_virtualbox/`}</a></p>
    <p>{`To install Git you can use this website
`}<a parentName="p" {...{
        "href": "https://linuxhint.com/git-source-code-management-system/"
      }}>{`https://linuxhint.com/git-source-code-management-system/`}</a></p>
    <p>{`Then you need to add an SSH key to your GitHub account using this website
`}<a parentName="p" {...{
        "href": "https://docs.github.com/en/github/authenticating-to-github/adding-a-new-ssh-key-to-your-github-account"
      }}>{`https://docs.github.com/en/github/authenticating-to-github/adding-a-new-ssh-key-to-your-github-account`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      